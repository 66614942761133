import * as Dialog from "@radix-ui/react-dialog";
import { forwardRef } from "react";
import { CloseUnfilled } from "./Icons";
import { cva, cx, VariantProps } from "class-variance-authority";
import { Separator } from "./Separator";

const dialogCss = cx(
    "fixed z-20 flex w-dvw flex-col gap-2 bg-white transition ease-in-out data-[state=closed]:duration-400 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out",
    "lg:inset-1/2 lg:h-max lg:max-h-[80%] lg:min-h-[50%] lg:max-w-[628px] lg:-translate-x-1/2 lg:-translate-y-1/2 lg:gap-8 lg:rounded-xl lg:py-8 lg:transition-none lg:data-[state=closed]:fade-out-0 lg:data-[state=open]:fade-in-0 lg:data-[state=closed]:zoom-out-95 lg:data-[state=open]:zoom-in-95 lg:data-[state=closed]:slide-out-to-left-1/2 lg:data-[state=closed]:slide-out-to-top-[48%] lg:data-[state=open]:slide-in-from-left-1/2 lg:data-[state=open]:slide-in-from-top-[48%]",
);
const sheetVariants = cva("pb-6", {
    variants: {
        side: {
            top: "inset-x-0 top-0 h-4/5 rounded-b-xl border-b pt-6 data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
            bottom: "inset-x-0 bottom-0 h-4/5 rounded-t-xl border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
            left: "inset-y-0 left-0 h-full w-3/4 rounded-none border-r pt-6 data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm md:rounded-r-2xl",
            right: "inset-y-0 right-0 h-full w-3/4 rounded-none border-l pt-6 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm md:rounded-l-2xl",
        },
    },
    defaultVariants: {
        side: "bottom",
    },
});

const DialogSheet = Dialog.Root;

const DialogTrigger = Dialog.Trigger;

export interface DialogContentProps
    extends React.ComponentPropsWithoutRef<typeof Dialog.Content>,
        VariantProps<typeof sheetVariants> {}

const DialogContent = forwardRef<
    React.ElementRef<typeof Dialog.Content>,
    DialogContentProps
>(({ children, className, side = "bottom", ...props }, ref) => (
    <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-20 bg-black/40" />
        <Dialog.Content
            ref={ref}
            className={cx(dialogCss, sheetVariants({ side }), className)}
            {...props}
        >
            {side == "bottom" && (
                <div className="mx-auto mb-4 mt-2 h-2 w-[84px] rounded-xl bg-blue-grey-50" />
            )}
            {children}
        </Dialog.Content>
    </Dialog.Portal>
));
DialogContent.displayName = Dialog.Content.displayName;

const DialogTitle = forwardRef<
    React.ElementRef<typeof Dialog.Title>,
    React.ComponentPropsWithoutRef<typeof Dialog.Title>
>(({ children, className, ...props }, ref) => (
    <Dialog.Title
        ref={ref}
        className={cx(
            "lg:typography-h2 typography-h3 font-bold text-blue-grey-900 lg:font-bold",
            className,
        )}
        {...props}
    >
        {children}
    </Dialog.Title>
));
DialogTitle.displayName = Dialog.Title.displayName;

const DialogClose = forwardRef<
    React.ElementRef<typeof Dialog.Close>,
    React.ComponentPropsWithoutRef<typeof Dialog.Close>
>(({ children, className, ...props }, ref) => (
    <Dialog.Close
        ref={ref}
        aria-label="Close"
        className={cx(
            "cursor-pointer border-none bg-transparent p-0 text-blue-grey-900",
            className,
        )}
        {...props}
    >
        {children}
    </Dialog.Close>
));
DialogClose.displayName = Dialog.Close.displayName;

export interface DialogHeaderProps
    extends React.ComponentPropsWithoutRef<typeof Dialog.Title> {
    onClose?: () => void;
    showSeparator?: boolean;
}
const DialogHeader = forwardRef<
    React.ElementRef<typeof Dialog.Title>,
    DialogHeaderProps
>(({ children, onClose, showSeparator = true, ...props }, ref) => (
    <div className="px-4">
        <div className="flex justify-between pb-3">
            <DialogTitle ref={ref} {...props}>
                {children}
            </DialogTitle>
            <DialogClose onClick={onClose}>
                <CloseUnfilled className="text-blue-grey-900" />
            </DialogClose>
        </div>
        {showSeparator && <Separator />}
    </div>
));
DialogHeader.displayName = "DialogHeader";

const DialogDescription = forwardRef<
    React.ElementRef<typeof Dialog.Description>,
    React.ComponentPropsWithoutRef<typeof Dialog.Description>
>(({ children, className, ...props }, ref) => (
    <Dialog.Description
        ref={ref}
        className={cx("h-full min-h-0 px-4", className)}
        {...props}
    >
        {children}
    </Dialog.Description>
));
DialogDescription.displayName = Dialog.Description.displayName;

export {
    DialogSheet,
    DialogClose,
    DialogTitle,
    DialogHeader,
    DialogContent,
    DialogDescription,
    DialogTrigger,
};
